










// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
import PageRow from '@/components/PageRow.vue';
import EquipmentDevices from '@/components/equipment/EquipmentDevices.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageEquipment',
  components: { Page, PageRow, EquipmentDevices }
});
